<template>
	<div class="section_wrap pt-50" style="padding-bottom: 70px">
		<div class="faq_list_group">
			<ul>
				<!-- TODO : 신규글 NEW 배지는 <em> 태그에 .new 추가 -->
				<template
					v-if="items_category.length > 0"
				>
					<li
						v-for="(category, index) in items_category"
						:key="'bbs_' + index"
					>
						<!-- 제목 -->
						<div
							class="faq_list_subject list_flex"
							@click="getFaqList(category, index, category.faq_number)"
						>
							<div class="list_tit">{{ category.faq_title }}</div>
							<div
								class="faq_list_subject_arrow"
							><i
								:class="category.is_view ? 'icon-arrow-up' : 'icon-arrow-down'"
							></i></div>
						</div>
						<!-- //제목 -->
						<!-- 내용 -->
						<ul
							class="faq_sub_list"
							v-if="category.is_view"
						>
							<li
								v-for="(item, index2) in category.items"
								:key="'item_' + index + '_' + index2"
							>
								<div class="faq_sub_list_tit list_flex"  @click="getFaqInfo(category, index, item, index2, item.faq_number)">
									<div class="list_tit">{{ item.faq_title }}</div>
									<div class="faq_sub_list_arrow"><i :class="item.is_view ? 'icon-arrow-up' : 'icon-arrow-down'"></i></div>
								</div>
								<transition name="slide-fade">
								<div
									v-if="item.is_view"
									class="faq_sub_list_view"
								>
									<p :inner-html.prop="item.content | nl2br"></p>
								</div>
								</transition>
							</li>
						</ul>
						<!-- //내용 -->
					</li>
				</template>
				<li
					v-else
				>no data</li>
			</ul>
		</div>
	</div>
</template>

<style>

.slide-fade-enter-active {
	transition: all .3s ease;
}
.slide-fade-leave-active {
	transition: all .0s ease;
}
.slide-fade-enter{
	transform: translateY(-10%);

	overflow: hidden;
	height: 100%;
}
.slide-fade-leave, .slide-fade-leave-to {
	transform: translateY(-10%) ;
	opacity: 0;
	overflow: hidden;
}
</style>
<script>
export default {
	name: 'bbs'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.common.community
				, title: 'FAQ'
				, b_code: this.$route.params.b_code
				, type: 'mypage'
			}
			,items : []
			, items_category: []
		}
	}
	, methods: {
		getCategory: async function(){
			try {
				this.$bus.$emit('on', true)

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_faq_category
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if (result.success) {
					this.items_category = result.data.faq_catgy_list
				} else {
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getFaqList: async function(category, index, faq_number){

			if(!category.items){
				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_faq_list
						, data: {
							member_number: this.user.member_number
							, faq_number: faq_number
							, page_number: 1
							, pagerecnum: 100
						}
						, type: true
					})
					if (result.success) {
						this.$set(category, 'items', result.data.faq_list)
					} else {
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			if(category.is_view){

				category.is_view = false
			}else{
				let self = this
				this.items_category.forEach(function(item){
					if(category == item){
						self.$set(item, 'is_view', true)
					}else{
						self.$set(item, 'is_view', false)
					}
				})
			}
		}

		,getFaqInfo: async function(item_category, category_index, item, item_index, faq_number){

			if(!item.content){

				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_faq_info
						, data: {
							member_number: this.user.member_number
							, faq_number: faq_number
						}
						, type: true
					})
					if (result.success) {
						this.$set(item, 'content', result.data.faq_contents)
					} else {
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify',  { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			if(item.is_view){
				this.$set(item, 'is_view', false)
			}else{
				let self = this
				this.items_category.forEach(function(category){
					console.log('category', category)
					if(category.items){
						category.items.forEach(function(item2){
							if(item == item2){
								self.$set(item2, 'is_view', true)
							}else{
								self.$set(item2, 'is_view', false)
							}
						})
					}
				})
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getCategory()
	}
}
</script>